import { SDKConfig } from './Config';
import { stringify } from 'query-string';

import AdviseroLogo from '../assets/advisero-logomark.svg';
import CloseWidget from '../assets/close-widget.svg';

/**
 * Advisero widget SDK
 *
 * @hideconstructor
 */
export class SDK {
  /**
   * Create and initialize new widget.
   *
   * @param {SDKConfig} config - widget's config
   */
  constructor(config) {
    this._iframeReloadTimeout = 0;
    this._isIframeInitialized = false;
    this._isWidgetInitializedFirstTime = false;

    // By setting that flag to true, next iframe initialized message will trigger widget init
    this._isWidgetInitializationRequested = false;

    this.config = config;
    this.iframe = this._createIframe();
    this.iframeWrapper = this._createIframeWrapper();
    this.widgetRoot = document.querySelector(this.config.widgetRoot);
    this.button = this._createButton();

    this.iframeWrapper.appendChild(this.iframe);
    this.widgetRoot.appendChild(this.iframeWrapper);

    this._initInternalEvents();
    this._setIframeSize();
    this._isWidgetOpen = false;

    if (this.config.layout.showButton) {
      this.iframeWrapper.appendChild(this.button);
      this.button.addEventListener('click', () => this._handleButtonClick());

      const css = `
        .hum-widget-toggle-button { background: ${this.config.theme.primaryFontColor}; }
        .hum-widget-toggle-button:hover { background: ${this.config.theme.primaryColor}; }
        .hum-widget-toggle-button:not(.is-active):hover svg path { fill: ${this.config.theme.primaryFontColor}; }
        .hum-widget-toggle-button.is-active { background: ${this.config.theme.primaryColor}; }
        .hum-widget-toggle-button.is-active svg path { fill: ${this.config.theme.primaryFontColor}; }
      `;
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }

  /**
   * Return if widget is open, based on its class
   *
   * @private
   */
  get _isWidgetOpen() {
    return this.iframe.classList.contains('is-open');
  }

  /**
   * Set open widget by manipulatng widget classes.
   *
   * @private
   */
  set _isWidgetOpen(value) {
    if (value) {
      this.button.classList.add('is-active');
      this.iframe.classList.add('is-open');
      this._initWidgetFirstTime();
      this.button.innerHTML = CloseWidget;
    } else {
      this.button.innerHTML = AdviseroLogo;

      this.button.classList.remove('is-active');
      this.iframe.classList.remove('is-open');
    }

    if (this.config.onWidgetToggle) {
      this.config.onWidgetToggle(value);
    }
  }

  /**
   * Toggle widget visibility.
   *
   * @public
   */
  toggleWidget() {
    this._isWidgetOpen = !this._isWidgetOpen;
  }

  /**
   * Show widget.
   *
   * @public
   */
  showWidget() {
    this._isWidgetOpen = true;
  }

  /**
   * Hide widget.
   *
   * @public
   */
  hideWidget() {
    this._isWidgetOpen = false;
  }

  /**
   * Close conversation
   *
   * @public
   */
  closeConversation() {
    this.iframe.contentWindow.postMessage({
      event: 'closeConversation'
    }, '*')
  }

  /**
   * Create and configure iframe.
   *
   * @private
   */
  _createIframe() {
    const iframe = document.createElement('iframe');
    iframe.classList.add('hum-iframe');
    iframe.setAttribute('frameborder', '0');

    const stringified = stringify({
      cid: this.config.cid,
      origin: window.location.href,
    }, {
      encode: true,
    });

    iframe.src = `${this.config.baseUrl}/widget/widget?${stringified}`;

    return iframe;
  }

  /**
   * Create iframe.
   *
   * @private
   */
  _createIframeWrapper() {
    const iframeWrapper = document.createElement('div');
    iframeWrapper.classList.add('hum-iframe-wrapper');
    iframeWrapper.style.position = this.config.theme.inline ? 'static' : 'fixed';

    return iframeWrapper;
  }

  /**
   * Create chat toggling button.
   *
   * @private
   */
  _createButton() {
    const button = document.createElement('div');
    button.classList.add('hum-widget-toggle-button');

    return button;
  }

  /**
   * Reload chat.
   * Useful if iframe is waken up by browser after suspend or sleep mode.
   *
   * @private
   */
  _reloadChat() {
    this.iframe.src = this.iframe.src;
    this._iframeReloadTimeout = window.setTimeout(() => this._reloadChat(), 10000);
    this._isWidgetInitializationRequested = true;
  }

  /**
   * Init widget, once.
   *
   * @private
   */
  _initWidgetFirstTime() {
    if (this._isWidgetInitializedFirstTime) {
      return;
    }

    this._initWidget();
  }

  /**
   * Init widget by sending initialization message.
   *
   * @private
   */
  _initWidget() {
    if (!this._isIframeInitialized) {
      this._isWidgetInitializationRequested = true;
      return;
    }

    this._isWidgetInitializedFirstTime = true;
    this._isWidgetInitializationRequested = false;
    this.iframe.contentWindow.postMessage({
      event: 'initializeChat'
    }, '*')
  }

  /**
   * Handle various window messages.
   *
   * @private
   */
  _initInternalEvents() {
    window.addEventListener('message', (event) => {
      const eventName = event.data.event;
      const payload = event.data.payload;

      switch (eventName) {
        case 'iframeLoaded': return this._handleIframeLoadedMessage();
        case 'reloadChat': return this._handleReloadChatMessage();
        case 'widgetInitialized': return this._handlewidgetInitializedMessage();
        case 'getCustomerData': return this._handleGetCustomerDataMessage();
        case 'newMessageEvent': return this._handleNewMessageEvent();
        case 'toggleExtension': return this._handleToggleExtensionMessage(payload);
        case 'closeWidget': return this._handleCloseWidgetMessage();
      }
    })
  }

  /**
   * Handle click on button toggling widget.
   *
   * @private
   */
  _handleButtonClick() {
    this.toggleWidget();
  }

  /**
   * Handle iframe loaded messages and sends configuration.
   *
   * @private
   */
  _handleIframeLoadedMessage() {
    this.iframe.contentWindow.postMessage({
      event: 'configuration',
      payload: this.config.getStructuredConfig(),
    }, '*')
  }

  /**
   * Handle `reloadChat` window message.
   *
   * @private
   */
  _handleReloadChatMessage() {
    if (navigator.onLine) {
      this._reloadChat();
    } else {
      const reloadChatWhenOnline = () => {
        window.removeEventListener('online', reloadChatWhenOnline);
        this._reloadChat();
      };

      window.addEventListener('online', reloadChatWhenOnline);
    }
  }

  /**
   * Handle `widgetInitialized` window message.
   *
   * @private
   */
  _handlewidgetInitializedMessage() {
    this._isIframeInitialized = true;

    if (this._isWidgetInitializationRequested) {
      this._initWidget();
    }

    window.clearTimeout(this._iframeReloadTimeout);
  }

  /**
   * Handle `getCustomerData` window message.
   *
   * @private
   */
  _handleGetCustomerDataMessage() {
    this.config.getCustomerData((err, data) => {
      this.iframe.contentWindow.postMessage({
        event: 'customerData',
        payload: data,
      }, '*')
    });
  }

    /**
   * Handle `newMessageEvent` window message.
   *
   * @private
   */
  _handleNewMessageEvent() {
    if (this.config.onNewMessage) {
      this.config.onNewMessage(this._isWidgetOpen);
    }
  }

  /**
   * Handle `toggleExtension` window message.
   *
   * @private
   * @param {object} payload - message payload
   * @param {boolean} payload.isExtended - whether extension should be extended or not.
   */
  _handleToggleExtensionMessage(payload) {
    this._setIframeSize(payload.isExtended);
  }

  /**
   * Handle `closeWidget` window message.
   *
   * @private
   */
  _handleCloseWidgetMessage() {
    this.hideWidget();
  }

  /**
   * Set iframe size either to expanded or normal.
   *
   * @param {boolean} isExtended - determines whether iframe should be expanded or not
   * @private
   */
  _setIframeSize(isExtended = false) {
    if (isExtended) {
      this.iframe.style.width = this.config.layout.expandedSize[0];
      this.iframe.style.height = this.config.layout.expandedSize[1];
    } else {
      this.iframe.style.width = this.config.layout.size[0];
      this.iframe.style.height = this.config.layout.size[1];
    }
  }
}
