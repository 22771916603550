import './sdk-styles.css';
import { waitForWindowLoad } from './waitForWindowLoad';
import { SDK } from './SDK';
import { SDKConfigError, SDKConfig, Config } from './Config';

/**
 * Initialize Advisero widget by creating new instance of SDK.
 *
 * @param {Config} config - SDK configuration
 * @returns {Promise<SDK>} - new widget SDK instance
 *
 * @example
   // Initialize widget and open it immediately
   ADV.initWidget({
    cid: 'abcdef123456',
    baseUrl: 'https://advisero.pl/humanized',
    language: 'en',
    translations: {
      title: 'Agent Support Area',
    },
    getCustomerData: (done) => done(null, { externalId: 'myUserId' }),
    layout: {
      size: ['410px', '530px'],
      expandedSize: ['800px', '720px'],
    },
    theme: {
      consultantColor: '#fff',
      consultantFontColor: '#000',
      customerColor: '#333',
      customerFontColor: '#fff',
      primaryColor: '#f44',
      primaryFontColor: '#fff',
    },
    translations: {
      title: 'Advisero Bot',
      welcome: 'Wiadomość powitalna',
    },
   }).then((sdk) => sdk.showWidget())
 */
export function initWidget (config) {
  try {
    const sdkConfig = new SDKConfig(config);
    return waitForWindowLoad().then(() => new SDK(sdkConfig));
  } catch (e) {
    if (e instanceof SDKConfigError) {
      console.group(e.message);
      e.validationErrors.forEach(error => console.error(`Advisero SDK config error: ${error}`));
      console.error('Received config', config);
      console.groupEnd();
    } else {
      throw e;
    }
  }
}

// The code below is required to initialize the widget by the browser extension
const adviseroWidgetDiv = document.querySelector('#advisero-widget');

if (adviseroWidgetDiv) {
  const config = JSON.parse(adviseroWidgetDiv.dataset.config);

  initWidget({
    ...config,
    getCustomerData: (done) => {
      let externalId = localStorage.getItem('testWidget_externalId');
      if (!externalId) {
        externalId = '_' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('testWidget_externalId', externalId);
      }

      done(null, {
        externalId,
      });
    },
  });
}
