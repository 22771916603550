export const waitForWindowLoad = () => new Promise((resolve) => {
  if (window.document.readyState === 'complete') {
    return resolve();
  }

  const onLoad = () => {
    window.removeEventListener('load', onLoad);
    return resolve();
  }

  window.addEventListener('load', onLoad);
});
