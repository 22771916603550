/**
 * Check whether object was created with "{}" or not.
 *
 * https://stackoverflow.com/a/43773857
 */
const isPlainObject = (obj) => {
  try {
    return Object.getPrototypeOf(obj) === Object.prototype
  } catch {
    // Catch errors (like trying to verify null), and fallback to false.
    return false
  }
}

/**
 * Deep clean undefined values from object.
 * Modifies original.
 *
 * @param {*} obj - object to deeply clenaup
 */
export const deepUndefinedClean = (obj) => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  Object.keys(obj).forEach((prop) => {
    const value = obj[prop];

    if (value === undefined) {
      return delete obj[prop];
    }

    if (isPlainObject(value)) {
      return deepUndefinedClean(value);
    }
  });

  return obj;
}
