import merge from 'deepmerge';
import rfdc from 'rfdc';
import { deepUndefinedClean } from './deepCleaner';

const deepClone = rfdc({ proto: false });

/**
 * SDK configuration.
 *
 * @typedef Config
 * @property {string} cid - client id
 * @property {string} baseUrl - Humanized API url
 * @property {object} [branding] - configuration of branding assets
 * @property {object} [branding.logoAvatar] - URL of circle logo avatar
 * @property {object} [branding.logoTitle] - URL of title-wide logo
 * @property {string} [language=pl] - Widget language in ISO 639-1 format
 * @property {string} [widgetRoot="body"] - root element selector for widget's button and chat window
 * @property {string} [initialIntent] - Initial intent.
 *  When no intent is defined, default one, defined in Humanized settings is used.
 * @property {GetCustomerData} getCustomerData - customer data callback function
 * @property {NewMessageEvent} [newMessageEvent] - Deprecated, use onNewMessage instead | show new message event callback function
 * @property {OnNewMessage} [onNewMessage] - show new message event callback function
 * @property {OnWidgetToggle} [onWidgetToggle] - open/close state of widget cb function
 * @property {object} layout - layout configuration
 * @property {boolean} [layout.showButton=true] - show default widget toggle button
 * @property {string[]} layout.size - tuple for chat's `[width, height]`, any valid CSS metric
 * @property {string[]} layout.expandedSize - tuple for chat's extended `[width, height]`, any valid CSS metric
 * @property {boolean} [layout.showCloseButton=true] - show close button in widget
 * @property {boolean} [layout.showMoreButton=true] - show more button in widget
 * @property {boolean} [layout.adviseroPageRedirect=true] - link to advisero page in footer
 * @property {object} [theme] - theme configuration
 * @property {boolean} [theme.inline=false] - show widget inline in given `widgetRoot`, instead of browser corner
 * @property {string} [theme.primaryColor="#999"] - primary color for interface, any valid CSS color
 * @property {string} [theme.primaryFontColor="#fff"] - color for font on primary background, any valid CSS color
 * @property {string} [theme.customerColor="#888"] - color for consumer, any valid CSS color
 * @property {string} [theme.customerFontColor="#fff"] - color for font on consumer background, any valid CSS color
 * @property {string} [theme.consultantColor="#aaa"] - color for consultant, any valid CSS color
 * @property {string} [theme.consultantFontColor="#fff"] - color for font on consultant background, any valid CSS color
 * @property {string} [theme.avatarColor="#aaa"] - color for avatar, any valid CSS color
 * @property {string} [theme.avatarFontColor="#aaa"] - color for font on avatar, any valid CSS color
 * @property {string} [theme.botColor="#aaa"] - color for bot, any valid CSS color
 * @property {string} [theme.botFontColor="#fff"] - color for font on bot background, any valid CSS color
 * @property {string} [theme.dividerColor="#ccc"] - color for lines of divider, any valid CSS color
 * @property {string} [theme.dividerFontColor="#999"] - color for font of divider, any valid CSS color
 * @property {string} [theme.welcomeMessageColor="#999"] - color for font of welcome message, any valid CSS color
 * @property {string} [theme.botAvatarUrl=""] - custom bot avatar image url
 * @property {object} [translations] - configuration of various texts
 * @property {string} [translations.title="Advisero"] - title of the conversation window
 * @property {string} [translations.caption="Wirtualny Asystent"] - caption of the conversation window
 * @property {string} [translations.welcome=null] - welcome message, `null` for disabling
*/

/**
 * Callback called by Widget when customer information is requested.
 *
 * @callback GetCustomerData
 * @param {GetCustomerDataDone} done - call `done` with user information if its ready
 * @returns {void}
 */

/**
 * Callback called by SDK user inside {@link GetCustomerData} if the information is ready
 *
 * @callback GetCustomerDataDone
 * @param {Error} error - call with error
 * @param {any} data - call with user information
 * @returns {void}
 */

/**
 * Callback called by widget when received new message
 *
 * @callback OnNewMessage
 * @param {boolean} isWidgetOpen
 * @returns {void}
 */

/**
 * Callback called by widget when received new message
 *
 * @callback NewMessageEvent
 * @param {boolean} isWidgetOpen
 * @returns {void}
 * @deprecated
 *
 */

/**
 * Callback called by Widget, when widget is being closed/opened
 *
 * @callback OnWidgetToggle
 * @param {boolean} isWidgetOpen
 * @returns {void}
 */

/**
 * SDK Config error thrown if invalid config is provided.
 *
 * @private
 * @extends {Error}
 */
export class SDKConfigError extends Error {
  /**
   * @param {string[]} errors
   * @param  {...any} params
   */
  constructor(errors, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SDKConfigError);
    }

    this.name = 'SDKConfigError';
    this.message = 'Invalid SDK config.';
    this.validationErrors = errors;
  }
}

/**
 * SDK Config DTO, autovalidates itself upon creation.
 *
 * @private
 */
export class SDKConfig {

  /**
   * Default config values for optional parameters
   */
  static DEFAULT_CONFIG = {
    widgetRoot: 'body',
    layout: {
      showButton: true,
      showCloseButton: true,
      showMoreButton: true,
      adviseroPageRedirect: true,
      size: ['410px', '630px'],
      expandedSize: ['600px', '700px'],
    },
    language: 'pl',
    branding: {
      logoAvatar: null,
      logoTitle: null,
    },
    translations: {
      title: 'Advisero',
      caption: 'Wirtualny Asystent',
      welcome: null,
    },
    theme: {
      inline: false,
      avatarColor: '#F66376',
      avatarFontColor: '#FFFFFF',
      botColor: '#FFFFFF',
      botFontColor: '#333333',
      consultantColor: '#FFFFFF',
      consultantFontColor: '#333333',
      customerColor: '#353582',
      customerFontColor: '#FFFFFF',
      primaryColor: '#353582',
      primaryFontColor: '#FFFFFF',
      dividerColor: '#DEDEDE',
      dividerFontColor: '#999999',
      welcomeMessageColor: '#8097B1',
      carouselBacgroundColor: '#FFFFFF',
      botAvatarUrl: ""
    }
  }

  /**
   * @param {Config} config
   */
  constructor(config) {
    config = deepUndefinedClean(deepClone(config));

    const errors = this._validateConfig(config);
    if (errors.length > 0) {
      throw new SDKConfigError(errors);
    }

    this.config = merge(SDKConfig.DEFAULT_CONFIG, config, {
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
    Object.freeze(this.config);

    this.cid = this.config.cid;
    this.baseUrl = this.config.baseUrl;
    this.widgetRoot = this.config.widgetRoot;
    this.language = this.config.language;
    this.initialIntent = this.config.initialIntent;
    this.getCustomerData = this.config.getCustomerData;
    this.onNewMessage = this.config.onNewMessage || this.config.newMessageEvent;
    this.onWidgetToggle = this.config.onWidgetToggle;
    this.layout = this.config.layout;
    this.theme = this.config.theme;
    this.translations = this.config.translations;
  }

  /**
   * Returns config with some fields removed, that cannot be converted into structured clone.
   */
  getStructuredConfig() {
    const clonedConfig = deepClone(this.config);
    delete clonedConfig.getCustomerData;
    delete clonedConfig.onNewMessage;
    delete clonedConfig.newMessageEvent;
    delete clonedConfig.onWidgetToggle;

    return clonedConfig;
  }

  /**
   * Validates given config.
   *
   * @param {Config} config
   * @returns String[] list of errors in given configuration.
   * @private
   */
  _validateConfig(config) {
    const errors = [];

    if (!config) {
      errors.push('No config provided.');
      return errors;
    }

    if (typeof config.cid !== 'string' || config.cid.length === 0) {
      errors.push('Please provide `cid`.');
    }

    if (typeof config.baseUrl !== 'string' || config.baseUrl.length === 0) {
      errors.push('Please provide `baseUrl`.');
    }

    if (this._isDefined(config.widgetRoot) && typeof config.widgetRoot !== 'string') {
      errors.push('`widgetRoot` should be of type: String.');
    }

    if (this._isDefined(config.initialIntent) && typeof config.initialIntent !== 'string') {
      errors.push('`initialIntent` should be of type: String.');
    }

    if (
      this._isDefined(config.language)
      && (typeof config.language !== 'string' || config.language.length === 0)
    ) {
      errors.push('Please provide `language`.');
    }

    if (typeof config.getCustomerData !== 'function') {
      errors.push('Please provide `getCustomerData` function.');
    }

    if (this._isDefined(config.onNewMessage) && typeof config.onNewMessage !== 'function') {
      errors.push('`onNewMessage` should be of type: Function.');
    }

    if (this._isDefined(config.newMessageEvent) && typeof config.newMessageEvent !== 'function') {
      errors.push('`newMessageEvent` should be of type: Function.');
    }

    if (this._isDefined(config.onWidgetToggle) && typeof config.onWidgetToggle !== 'function') {
      errors.push('`onWidgetToggle` should be of type: Function.');
    }

    if (config.layout) {
      if (this._isDefined(config.layout.showButton) && typeof config.layout.showButton !== 'boolean') {
        errors.push('`layout.showButton` should be of type: Boolean.');
      }
      if (this._isDefined(config.layout.showCloseButton) && typeof config.layout.showCloseButton !== 'boolean') {
        errors.push('`layout.showCloseButton` should be of type: Boolean.');
      }
      if (this._isDefined(config.layout.showMoreButton) && typeof config.layout.showMoreButton !== 'boolean') {
        errors.push('`layout.showMoreButton` should be of type: Boolean.');
      }

      if (
        this._isDefined(config.layout.size)
        && (!Array.isArray(config.layout.size) || !this._isValidSizeTuple(config.layout.size))
      ) {
        errors.push('Please provide valid `layout.size` tuple (`[width, height]`).');
      }

      if (
        this._isDefined(config.layout.expandedSize)
        && (!Array.isArray(config.layout.expandedSize) || !this._isValidSizeTuple(config.layout.expandedSize))
      ) {
        errors.push('Please provide valid `layout.expandedSize` tuple (`[width, height]`).');
      }
    }

    if (config.theme) {
      if (this._isDefined(config.theme.inline) && typeof config.theme.inline !== 'boolean') {
        errors.push('`theme.inline` should be of type: Boolean.');
      }

      if (this._isDefined(config.theme.consultantColor) && !this._isValidColor(config.theme.consultantColor)) {
        errors.push('Please provide `theme.consultantColor`.');
      }

      if (this._isDefined(config.theme.consultantFontColor) && !this._isValidColor(config.theme.consultantFontColor)) {
        errors.push('Please provide `theme.consultantFontColor`.');
      }

      if (this._isDefined(config.theme.customerColor) && !this._isValidColor(config.theme.customerColor)) {
        errors.push('Please provide valid `theme.customerColor`.');
      }

      if (this._isDefined(config.theme.customerFontColor) && !this._isValidColor(config.theme.customerFontColor)) {
        errors.push('Please provide valid `theme.customerFontColor`.');
      }

      if (this._isDefined(config.theme.primaryColor) && !this._isValidColor(config.theme.primaryColor)) {
        errors.push('Please provide valid `theme.primaryColor`.');
      }

      if (this._isDefined(config.theme.primaryFontColor) && !this._isValidColor(config.theme.primaryFontColor)) {
        errors.push('Please provide valid `theme.primaryFontColor`.');
      }
    }

    if (config.translations) {
      if (this._isDefined(config.translations.welcome) && typeof config.translations.welcome !== 'string') {
        errors.push('`translations.welcome` should be of type: String.');
      }

      if (this._isDefined(config.translations.welcome) && typeof config.translations.title !== 'string') {
        errors.push('`translations.title` should be of type: String.');
      }
    }

    return errors;
  }

  /**
   * Returns whether field is correct CSS color.
   * Uses browser engine to determine if color is correct.
   *
   * @param {string} color - color to validate
   * @return {boolean}
   * @private
   */
  _isValidColor(color) {
    const tmpEl = document.createElement('span');
    tmpEl.style.color = color;

    // default color for element is empty, if browser didn't allow to set the value
    // it will still be empty
    return tmpEl.style.color !== '';
  }

  /**
   * Returns whether given size tuple is valid.
   * Uses browser engine to determine if metric value is correct.
   *
   * @param {string[]} tuple - tuple to validate
   * @return {boolean}
   * @private
   */
  _isValidSizeTuple(tuple) {
    const tmpEl = document.createElement('span');
    tmpEl.style.width = tuple[0];
    tmpEl.style.height = tuple[1];

    // default color for width/height is empty, if browser didn't allow to set the value
    // it will still be empty
    return (tmpEl.style.width !== '') && (tmpEl.style.height !== '');
  }

  /**
   * Returns whether value is defined
   * @private
   */
  _isDefined(value) {
    return typeof value !== 'undefined';
  }
}
